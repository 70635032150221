<template>
  <div class="my-goods">
    <top-bar />
    <page-title v-bind="info" :image="flower1" />

    <div class="container">
      <n-tabs :data="tabs" state>
        <template #tab="{item}">
          {{ item.title }}
        </template>
      </n-tabs>
    </div>

    <n-tabs-content>
      <template #active>
        <n-loader :loading="$var('load')" />
        <div class="container">
          <div v-if="$n.size(products)">
            <product-card v-for="product in products" :key="product.id" :product="product" @status="status" />
          </div>
          <div v-else-if="!$var('load')" class="page-message">У вас нет товаров</div>
        </div>
      </template>
      <template #archive>
        <n-loader :loading="$var('load')" />
        <div class="container">
          <div v-if="$n.size(products)">
            <product-card v-for="product in products" :key="product.id" :product="product" @status="status" />
          </div>
          <div v-else-if="!$var('load')" class="page-message">У вас нет архивных товаров</div>
        </div>
      </template>
    </n-tabs-content>
  </div>
</template>

<script>
import flower1 from 'assets/flower1.png'
import ProductCard from './ProductCard'

export default {
  name: 'Index',
  components: { ProductCard, },
  data() {
    return {
      flower1,
      products: [],
      tabs: [
        { name: 'active', title: 'Мои товары', },
        { name: 'archive', title: 'Архив', },
      ],
    }
  },
  computed: {
    tab() {
      return this.$route.query.tabsDefault
    },
    info() {
      return {
        title: 'Мои товары',
        description: 'В этом разделе отображаются созданные товары',
        button: 'Архив',
        link: { query: { archive: null, }, },
      }
    },
  },
  watch: {
    tab() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true )
      this.products = []
      const api = $app.auth.user().hasStore ? $api.my.storeProducts() : $api.my.products()
      api.with('images').filter({ status: this.tab === 'archive' ? 'not:active' : 'active', }).then((response)=>{
        this.products = response.data.content
      }).finally(()=>{
        this.$var('load', false)
      })
    },
    status({ product, status, }) {
      this.$var('load', true)
      $api.app.products.edit(product.id, { status, }).then((response) => {
        this.load()
      }).catch(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.my-goods{
  .n-tabs-content{
    position: relative;
    padding-top: 10px;
    .container {
      min-height: 300px;
    }
  }
}
</style>
