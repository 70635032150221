<template>
  <div class="product-card">
    <div class="card">
      <div class="header">
        <div class="status" :class="$n.productStatus(product.status).color">
          {{ $n.productStatus(product.status).title }}
        </div>
        <div class="date">Дата создания: {{ $app.date.format(product.createdAt) }}</div>
      </div>
      <div class="body" @click="cardClick">
        <div class="left">
          <div class="order-image">
            <div v-for="image in product.images" :key="image.id"
                 :class="['image', {small: product.images.length > 1}]">
              <div class="image-offset">
                <img :src="image.src" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="info">
            <div class="title">{{ product.title }}</div>
            <div class="views-count">
              <div>Просмотры:</div>
              <div>{{ product.viewsCount }}</div>
            </div>
            <div class="views-count">
              <div>Клики "Позвонить":</div>
              <div>{{ product.callCount }}</div>
            </div>
            <div class="views-count">
              <div>Продажи:</div>
              <div>{{ product.salesCount }}</div>
            </div>
            <!--            <div>Способ отправки: <span class="dark">{{ product.getting }}</span></div>-->
          </div>
          <div class="wrap">
            <template v-if="$app.auth.user().hasStore">
              <n-button v-if="product.status === 'active'" color="default" @click.stop="changeStatus('canceled')">
                В архив
              </n-button>
              <n-button v-if="product.status === 'canceled'" color="default" @click.stop="changeStatus('active')">
                Активировать
              </n-button>
            </template>
            <template v-else>
              <!--              <n-button v-if="product.status === 'active'" color="default" @click="edit('canceled')">Отменить</n-button>-->
            </template>
            <div class="price">{{ $n.numberFormat(product.cost) }} ₸</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: { product: { type: Object, required: true, }, },
  methods: {
    changeStatus(status) {
      return this.$emit('status', { product: this.product, status, })
    },
    cardClick() {
      this.$router.push({ name: 'products.index', params: { id: this.product.id, }, })
    },
  },
}
</script>

<style scoped lang="scss">
.product-card {
  .card {
    margin-bottom: 20px;

    .n-link {
      color: inherit;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .status {
        font-size: 12px;
        line-height: 14px;

        &.success {
          color: #169401;
        }

        &.danger {
          color: #FF5139;
        }
      }

      .date {
        font-size: 12px;
        line-height: 14px;
        color: #404040;
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      background: #FFF;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      border-radius: 14px;
      padding: 15px;

      .left {
        padding-right: 7px;
        width: 40%;

        .order-image {
          display: flex;
          flex-wrap: wrap;
          height: 115px;
          overflow: hidden;
        }

        .image {
          width: 115px;
          height: 115px;
          text-align: center;

          .image-offset {
            padding: 3px;
            height: 100%;
            width: 100%;
          }

          &.small {
            width: 57px;
            height: 57px;

            .plug {
              display: none;
            }

            &:nth-child(4) {
              .plug {
                display: block;
              }

              img {
                display: none;
              }
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }

      .right {
        padding-left: 7px;
        width: 60%;
        position: relative;

        a{
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }

      .title {
        margin-bottom: 6px !important;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #000;
      }

      .views-count{
        display: flex;
        align-items: center;
        color: #BABABA;
        font-size: 11px;

        div:last-child{
          margin-left: 4px;
          color: #000;
        }
      }

      .info {
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 14px;
        color: #BABABA;

        & > div {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .dark {
          color: #404040;
          margin-left: 3px;
        }
      }

      .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          color: #404040;
        }
      }
    }

    .n-button {
      font-size: 9px;
      line-height: 11px;
      color: #2A82D3;
      border-radius: 10px;
      border: solid 1px #F2F2F2;
    }
  }
}
</style>
